import { StoryblokComponent, storyblokEditable } from "gatsby-source-storyblok"
import React from "react"

import SliderSection from "../../../V2/Layouts/SliderSection"

export default function StoryblokSliderSection({
  blok,
}: Storyblok.BlokProps<Storyblok.SliderSection>) {
  return (
    <SliderSection
      id={blok.id}
      sectionId={blok._uid}
      headingSize={blok.headingSize}
      subheadingText={blok.subheadingText}
      headingText={blok.headingText}
      overrideHeadingColor={blok.overrideHeadingColor}
      cardWidth={blok.cardWidth}
      primaryBackgroundColor={blok.primaryBackgroundColor}
      secondaryBackgroundColor={blok.secondaryBackgroundColor}
      // @ts-ignore to have more strict typing compared to Storybloks generic object typing
      {...storyblokEditable(blok)}
    >
      {blok.content.map((content) => (
        <StoryblokComponent key={content._uid} blok={content} />
      ))}
    </SliderSection>
  )
}
