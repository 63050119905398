import classNames from "classnames"
import React from "react"

import CircledIconButton from "./Buttons/CircledIconButton"
import Typography, { TypographySize } from "./Typography"
import { Color } from "../../../constants/V2/color"

type Size = "small" | "medium" | "large"

type Props = {
  sectionId: string
  size: Size
  headingText?: string
  subheadingText?: string
  color: Color
}

const HEADING_SIZES: Record<Size, TypographySize> = {
  small: "lead-lg",
  medium: "h3",
  large: "h2",
}

const SUBHEADING_SIZES: Record<Size, TypographySize> = {
  small: "body-lg",
  medium: "body-lg",
  large: "lead-md",
}

export default function SliderSectionTitle({
  sectionId,
  size,
  headingText,
  subheadingText,
  color,
}: Props) {
  return (
    <div className="mb-2xl-v2 flex flex-col sm-v2:flex-row gap-20-v2 md-v2:gap-[224px] justify-between">
      <div className="max-w-full md-v2:w-[448px] lg-v2:w-[600px] flex flex-col gap-y-16-v2">
        {headingText ? (
          <Typography
            color={color}
            text={headingText}
            font="grotesk"
            weight="book"
            element="h2"
            size={HEADING_SIZES[size]}
          />
        ) : null}
        {subheadingText ? (
          <Typography
            color={color}
            text={subheadingText}
            font="grotesk"
            weight="book"
            size={SUBHEADING_SIZES[size]}
          />
        ) : null}
      </div>
      <div className="flex items-end gap-12-v2">
        <CircledIconButton
          color={color}
          className={classNames(
            `swiper-prev-${sectionId}`,
            "z-10 transform rotate-180"
          )}
        />
        <CircledIconButton
          color={color}
          className={classNames(`swiper-next-${sectionId}`, "z-10")}
        />
      </div>
    </div>
  )
}
